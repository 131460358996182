import { useTranslation } from 'next-i18next';
import { ChangeEvent, FC, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

import AmountInput from '@/components/AmountInput';
import FormControl from '@/components/Form';
import { IRanger } from '@/components/Ranger/index.d';
import { Body, Caption } from '@/components/Text';
import useCurrencyFormat from '@/services/Hooks/useCurrencyFormat';

const getNestedObject = (nestedObj: any, pathArr: string[]) =>
	pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), nestedObj);

const resolveFormVariant = (fieldName: string, errors: FieldErrors) => {
	const fields = fieldName.split('.');
	const error = getNestedObject(errors, fields);
	if (error) {
		return 'error';
	}

	return 'default';
};

const Ranger: FC<IRanger> = (p) => {
	const { t } = useTranslation('howitworks');
	const {
		setPrice,
		price,
		minPrice = 60,
		maxPrice = parseInt(t('common:limit.loan'), 10) || 5000,
	} = p;
	const currencyFormat = useCurrencyFormat();
	const inputRef = useRef(null);
	const active = '#32be50';
	const inactive = '#ffffff';

	const handleChange = (value: number) => {
		if (Number(value) > maxPrice) {
			setPrice(Math.max(Number(minPrice), Math.min(Number(maxPrice), Number(value))));
		} else {
			setPrice(value);
		}
	};

	const changeValue = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		const val = value.replace('€', '');
		const newValue = val.replace('£', '');
		const convertedValue = newValue.replace('.', '');

		if (value === '' || value === null || Number(convertedValue) < minPrice) {
			setPrice(minPrice);
		}
	};

	const formReturn = useForm({
		mode: 'onBlur',
	});

	const {
		formState: { errors },
		setValue,
	} = formReturn;

	const changeHandler = (min: number, max: number) => (event: any) => {
		const { value } = event.target;
		const progress = `${((price - min) / (maxPrice - max)) * 100}%`;
		setPrice(value);
		// @ts-ignore
		inputRef.current.style.background = `linear-gradient(90deg, ${active} 0% ${progress}%,   ${inactive} ${progress}% 100%)`;
	};

	const progress = `${((price - minPrice) / (maxPrice - minPrice)) * 100}%`;

	const styleInput = {
		background: `linear-gradient(90deg, ${active} 0% ${progress},   ${inactive} ${progress} 100%)`,
	};

	return (
		<>
			<div className="flex items-baseline justify-between">
				<Body weight="bold">{t('calculator.range.title')}</Body>
				<div className="w-28">
					<FormControl>
						<AmountInput
							unmaskedValue={(value) => {
								setValue('amount', value, { shouldDirty: true, shouldValidate: true });
								handleChange(parseFloat(value));
							}}
							defaultValue={price.toString()}
							value={price.toString()}
							onBlur={changeValue}
							variant={resolveFormVariant('amount', errors)}
						/>
					</FormControl>
				</div>
			</div>
			<div>
				<input
					ref={inputRef}
					min={minPrice}
					max={maxPrice}
					type="range"
					className=" w-full p-0 rounded-sm border border-tertiary-contrast"
					value={price}
					onChange={changeHandler(minPrice, maxPrice)}
					style={styleInput}
				/>
			</div>
			<div className="flex justify-between mt-0.5">
				<Caption variant="contrast-30">
					{currencyFormat(minPrice, { minimumFractionDigits: 2 })}
				</Caption>
				<Caption variant="contrast-30">
					{currencyFormat(maxPrice, { minimumFractionDigits: 2 })}
				</Caption>
			</div>
		</>
	);
};

export default Ranger;
