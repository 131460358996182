import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';

import Installment from '@/components/Installment';
import { IProps } from '@/modules/Calculator/Installment/index.d';
import { useCalculator } from '@/services/Hooks/useCalculator';
import { getInstallmentListStatic } from '@/services/Utils/Installment';

const InstallmentCalculator: FC<IProps> = ({ amount, spacing }) => {
	const { t } = useTranslation('howitworks');

	const [totalAmount, setTotalAmount] = useState<number>(0);

	const { selectedInstallment, setSelectedInstallment, getProductList } = useCalculator();

	useEffect(() => {
		setTotalAmount(amount);
	}, [amount]);

	return (
		<>
			<Installment
				products={getProductList()}
				selectedProduct={selectedInstallment}
				onChange={setSelectedInstallment}
				tableTitle={t('installmentTitle', {
					selectedInstallment: selectedInstallment?.instalments,
				})}
				set={getInstallmentListStatic({
					installment: selectedInstallment?.instalments,
					minDepositLimit: 3,
					t,
					total: totalAmount,
				})}
				variant="reverse"
				colorPalette={['reverse-content', 'tone-contrast-5']}
				amount={amount}
				spacing={spacing}
			/>
		</>
	);
};

export default InstallmentCalculator;
