/* eslint-disable no-nested-ternary */
import { FC } from 'react';

import { getWidth } from '@/components/Container';
import { IDynamicImageUrl } from '@/components/ImageContainer/DynamicImageUrl.d';
import {
	IBgPosition,
	IBgSize,
	IImageContainer,
	ImageVariant,
} from '@/components/ImageContainer/ImageContainer.d';
import useWindowWidth from '@/services/Hooks/useWindowWidth';

const getBackground = (variant: ImageVariant) => {
	switch (variant) {
		case 'car':
			return 'bg-center bg-cover bg-car-mobile lg:bg-car-mountain-banner';
		case 'sidecar':
			return 'bg-center bg-cover bg-sidecar-mobile md:bg-sidecar';
		case 'login-harley':
			return 'bg-center bg-cover bg-login-harley';
		case 'location-header':
			return 'bg-center bg-cover bg-location-header';
		case 'repairman':
			return 'bg-center bg-cover bg-repairman';
		case 'street':
			return 'bg-secondary bg-center bg-cover bg-street';
		case 'retention':
			return 'bg-center bg-cover bg-retention-banner-mobile md:bg-retention-banner-web';
		case 'tire-primary':
			return 'bg-primary bg-bumper-tire-pattern-cross';
		case 'tyre-tread-accent':
			return 'bg-success';
		case 'tyre-accent':
			return 'bg-accent';
		case 'tyre-tertiary-contrast':
			return 'bg-tertiary-contrast';
		case 'tire-secondary':
			return 'bg-tertiary bg-bumper-tire-pattern-secondary';
		case 'diamond-tone-contrast-5':
			return 'bg-tone-contrast-5 bg-diamond-tone-contrast-5';
		case 'round-secondary':
			return 'bg-secondary bg-round-secondary';
		case 'slotted-tread':
			return 'bg-slotted-tread';
		case 'slotted-tread-secondary':
			return 'bg-slotted-tread-secondary';
		case 'diamond-secondary':
			return 'bg-secondary bg-diamond-secondary';
		case 'man-child':
			return 'bg-secondary bg-man-child-mobile md:bg-man-child';
		case 'car-mountain':
			return 'bg-tertiary bg-car-mountain';
		case 'audi-mountain':
			return 'bg-tertiary bg-audi-mountain';
		case 'custom':
			return 'bg-secondary';
		case 'slotted-tread-accent':
			return 'bg-slotted-tread-accent';
		case 'round-tread-tertiary':
			return 'bg-round-tread-tertiary';
		case 'round-tread-tertiary-small':
			return 'bg-tertiary bg-round-tread-tertiary-small';
		case 'customer-at-dealership':
			return 'bg-center bg-customer-at-dealership';
		case 'driving-on-road':
			return 'bg-center bg-driving-on-road';
		case 'chilling-on-road':
			return 'bg-center bg-chilling-on-road';
		case 'tertiary':
			return 'bg-tertiary';
		default:
			return 'bg-transparent';
	}
};

const getBgSize = (size: IBgSize) => {
	switch (size) {
		case 'cover':
			return 'bg-cover';
		case 'contain':
			return 'bg-contain';
		case '8':
			return 'bg-8';
		case 'default':
			return '';
		default:
			return '';
	}
};

const getSpacing = (space: string) => {
	switch (space) {
		case 'none':
			return 'md:px-0';
		case 'default':
			return 'px-1';
		default:
			return 'px-1';
	}
};

const getPadding = (padding: string) => {
	switch (padding) {
		case 'none':
			return 'px-0';
		default:
			return 'md:px-2 px-1';
	}
};
const getBgPosition = (position: IBgPosition) => {
	switch (position) {
		case 'bottom':
			return 'bg-bottom';
		case 'center':
			return 'bg-left-bottom lg:bg-center';
		case 'left':
			return 'bg-left';
		case 'left-bottom':
			return 'bg-left-bottom';
		case 'left-top':
			return 'bg-left-top';
		case 'right':
			return 'bg-right';
		case 'right-bottom':
			return 'bg-right-bottom';
		case 'right-top':
			return 'bg-right-top';
		case 'default':
			return '';
		default:
			return '';
	}
};

const getDynamicBackgroundImage = (
	dynamicLargeImageUrl: IDynamicImageUrl | undefined,
	dynamicSmallImageUrl: IDynamicImageUrl | undefined,
	variant: ImageVariant
) => {
	if (dynamicLargeImageUrl && dynamicSmallImageUrl) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useWindowWidth() >= 650
			? dynamicLargeImageUrl?.data?.attributes.url ??
					dynamicLargeImageUrl?.data?.attributes?.formats?.large.url
			: dynamicSmallImageUrl?.data?.attributes.url ??
					dynamicSmallImageUrl?.data?.attributes?.formats?.large.url;
	}
	return getBackground(variant);
};

const ImageContainer: FC<IImageContainer> = ({
	variant = 'tire-primary',
	width = 'default',
	children,
	className = '',
	shade = '',
	wrapperClassName = '',
	bgSize = 'default',
	bgPosition = 'default',
	spacing = 'default',
	padding = 'default',
	dynamicLargeImageUrl,
	dynamicSmallImageUrl,
	...componentProps
}) => {
	const isDynamic = Boolean(dynamicLargeImageUrl || dynamicSmallImageUrl);

	return (
		<div
			className={`${
				dynamicLargeImageUrl ? '' : getBackground(variant)
			} ${wrapperClassName} relative ${getBgSize(bgSize)} ${getBgPosition(bgPosition)}`}
			{...componentProps}
			{...(isDynamic
				? {
						style: {
							backgroundImage: `url(${getDynamicBackgroundImage(
								dynamicLargeImageUrl,
								dynamicSmallImageUrl,
								variant
							)})`,
						},
				  }
				: {})}
		>
			<div className={`${shade} absolute w-full h-full top-0 z-0`} />
			<div className={`${getPadding(padding)} relative z-10`}>
				<div
					className={`md:px-0 ${getSpacing(spacing)} container mx-auto ${getWidth(
						width
					)} ${className}`}
				>
					{children}
				</div>
			</div>
		</div>
	);
};

export default ImageContainer;
