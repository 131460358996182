import { faTable } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';

import ButtonGroup from '@/components/ButtonGroup';
import Icon from '@/components/Icon';
import InfoBox from '@/components/InfoBox';
import IInstallment, { InstallmentTableType } from '@/components/Installment/index.d';
import InstallmentTable from '@/components/Installment/Table';
import { Body } from '@/components/Text';
import { IStoreAndAPIShared } from '@/redux/shared/index.d';
import useCurrencyFormat from '@/services/Hooks/useCurrencyFormat/';
import {
	depositPercentageSanitizer,
	getProductLabelsFromDynamicProducts,
} from '@/services/Utils/Installment';

const Installment: FC<IInstallment.IDynamicProps> = ({
	selectedProduct,
	products = [],
	showProducts = true,
	set,
	onChange = () => {},
	tableTitle = '',
	currency,
	error = false,
	title,
	showInfoBox = true,
	showMaxTooltips = false,
	amount,
	changePaymentDate,
	handleChangePaymentDate,
	colorPalette,
	tableSummary,
	infoText,
	variant = 'tone-contrast-5',
	collapsible = false,
	type = InstallmentTableType.NONE,
	spacing,
	structure,
	showStaticTableSummary = true,
}) => {
	const [active, setActive] = useState<IStoreAndAPIShared.IProduct | null>(selectedProduct || null);
	const { t } = useTranslation('common');
	const deposit = depositPercentageSanitizer(active?.deposit_percentage);
	const currencyFormat = useCurrencyFormat();
	const itemList =
		showMaxTooltips && amount
			? getProductLabelsFromDynamicProducts(products, amount, t, currencyFormat)
			: getProductLabelsFromDynamicProducts(products);

	// eslint-disable-next-line consistent-return
	const getInfoText = () => {
		if (infoText && showInfoBox) {
			return <InfoBox className="mt-0.5" text={infoText} />;
		}
		if (active && !error && showInfoBox && showProducts) {
			return (
				<InfoBox
					className={(deposit as number) > 0 ? '' : 'py-0 h-0 overflow-hidden'}
					text={t('payment.deposit', {
						amount: currencyFormat(set?.[0]?.amount || 0, {
							minimumFractionDigits: 2,
						}),
						instalment: active.instalments,
					})}
					isInfo
				/>
			);
		}
		if (error && active && showInfoBox) {
			return (
				<InfoBox
					className="mt-0.5"
					text={t('payment.error', {
						instalments: active.instalments,
						max: currencyFormat(selectedProduct?.max, {
							minimumFractionDigits: 2,
						}),
						min: currencyFormat(selectedProduct?.min, {
							minimumFractionDigits: 2,
						}),
					})}
					variant="error"
				/>
			);
		}
	};

	useEffect(() => {
		if (selectedProduct) {
			setActive(selectedProduct);
		}
	}, [selectedProduct]);

	return (
		<div data-cy="installmentDynamic">
			{showProducts && (
				<>
					{title ? (
						<Body weight="bold">
							<Icon iconName={faTable} variant="reverse" className="mr-0.5" />
							{title ?? t('payment.table')}
						</Body>
					) : null}

					<ButtonGroup
						className="mt-0.5 mb-0.75"
						itemList={itemList}
						active={active?.id}
						onClick={(item) => {
							const { id } = item;
							const product = products.filter(
								(prd: IStoreAndAPIShared.IProduct) => prd.id === id
							)[0];
							setActive(product);
							onChange(product);
						}}
					/>
				</>
			)}
			{getInfoText()}
			{active && !error && (
				<InstallmentTable
					colorPalette={colorPalette}
					changePaymentDate={changePaymentDate}
					handleChangePaymentDate={handleChangePaymentDate}
					currency={currency}
					className="mt-1"
					header={tableTitle}
					set={set}
					tableSummary={tableSummary}
					showStaticTableSummary={showStaticTableSummary}
					variant={variant}
					collapsible={collapsible}
					type={type}
					selectedProduct={selectedProduct}
					amount={amount}
					spacing={spacing}
					structure={structure}
				/>
			)}
		</div>
	);
};
export default Installment;
