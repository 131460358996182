import { faCheck, faExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'next-i18next';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';

import IAmountInput from '@/components/AmountInput/index.d';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import ErrorLabel from '@/components/Label/ErrorLabel';
import ISelect from '@/components/Select/index.d';
import { amountMaskValue, amountUnMaskValue } from '@/services/Utils/Locale/InputMasks';

const getVariant = (variant: ISelect.Variant) => {
	switch (variant) {
		case 'success':
			return {
				border: 'border-success',
				text: 'text-success',
			};
		case 'error':
			return {
				border: 'border-error',
				text: 'text-error',
			};
		default:
			return {
				border: 'border-tone-contrast-50',
				text: 'text-primary-content',
			};
	}
};

const defaultMaskOptions = {
	allowDecimal: true,
	allowLeadingZeroes: false,
	allowNegative: false,
	decimalLimit: 2,
	decimalSymbol: '.',
	includeThousandsSeparator: true,
	integerLimit: 5,
};

const AmountInput = forwardRef<any, IAmountInput.IProps>((p, ref) => {
	const {
		errors,
		testId,
		variant = 'default',
		withButton = false,
		unmaskedValue,
		customMask,
		onButtonClick,
		maskOptions,
		onChange,
		onBlur,
		name,
		buttonLabel = '',
		buttonDisabled,
		buttonShowLoader,
		buttonTestId,
		disabled,
		placeholder,
		onFocus,
		disableError = false,
		nullEqual = false,
		defaultValue,
	} = p;
	const { t } = useTranslation('common');
	const [amountValue, setAmountValue] = useState<string>('');
	const { border, text } = getVariant(variant);
	const isError = variant === 'error';
	const isSuccess = variant === 'success';

	const inputSuffix = t('currency.inputSuffix');
	const inputPrefix = t('currency.inputPrefix');
	const decimalSymbol = t('currency.decimalSymbol');
	const thousandsSeparatorSymbol = t('currency.thousandsSeparatorSymbol');
	const currencyMask = createNumberMask({
		...defaultMaskOptions,
		decimalSymbol,
		prefix: inputPrefix,
		suffix: inputSuffix,
		thousandsSeparatorSymbol,
		...maskOptions,
	});

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(e);
		}

		if (!nullEqual) {
			if (e.target.value) {
				setAmountValue(e.target.value);
				unmaskedValue(
					amountUnMaskValue(
						e.target.value,
						inputPrefix,
						inputSuffix,
						decimalSymbol,
						thousandsSeparatorSymbol
					)
				);
			}
		} else {
			setAmountValue(e.target.value);
			unmaskedValue(
				amountUnMaskValue(
					e.target.value,
					inputPrefix,
					inputSuffix,
					decimalSymbol,
					thousandsSeparatorSymbol
				)
			);
		}
	};

	const getValueToMaskedValue = (rawValue: string): string | undefined => {
		if (rawValue) {
			const maskedValue = amountMaskValue(rawValue, decimalSymbol, thousandsSeparatorSymbol);
			const { conformedValue } = conformToMask(maskedValue, currencyMask);
			return conformedValue;
		}
		return undefined;
	};

	useEffect(() => {
		if (defaultValue) {
			const convertedDefaultValue = getValueToMaskedValue(defaultValue.toString());
			setAmountValue(convertedDefaultValue || '');
		}
	}, []);

	// unmaskedvalue causing camelcase error when it s passed to dom so it needs to be deleted from 'p' object
	// https://stackoverflow.com/questions/49358560/react-wrapper-react-does-not-recognize-the-staticcontext-prop-on-a-dom-elemen
	const rest: any = { ...p };
	delete rest.unmaskedValue;
	delete rest.disableError;
	delete rest.nullEqual;

	return (
		<>
			<div className="flex flex-row">
				<div
					className={`relative flex flex-grow flex-row border rounded-full items-center align-middle hover:shadow-hover focus-within:shadow-focus ${border}`}
				>
					<MaskedInput
						mask={customMask || currencyMask}
						name={name}
						onBlur={onBlur}
						onFocus={onFocus}
						value={amountValue}
						type="text"
						placeholder={placeholder}
						id={name}
						className="text-n0 font-normal placeholder-tone-contrast-30 font-text whitespace-pre-line border-none rounded-full py-0.75 px-1 pr-2 w-full focus:outline-none disabled:bg-tertiary"
						onChange={handleOnChange}
						disabled={disabled}
						data-testid={testId}
						ref={(arf) => {
							if (ref) {
								// @ts-ignore
								ref(arf ? arf.inputElement : null);
							}
						}}
						{...rest}
					/>
					{isError && (
						<Icon
							iconName={faExclamation}
							variant="error"
							className={`absolute right-1 mr-0.75 w-5 h-5 ${text}`}
						/>
					)}
					{isSuccess && (
						<Icon
							iconName={faCheck}
							variant="success"
							className={`absolute right-1 mr-0.75 w-5 h-5 ${text}`}
						/>
					)}
				</div>
				{withButton && (
					<div className="ml-0.5 flex">
						<Button
							type="button"
							onClick={onButtonClick}
							label={buttonLabel}
							variant="accent"
							width="content"
							data-testid={buttonTestId}
							size="medium"
							disabled={buttonDisabled}
							showLoader={buttonShowLoader}
						/>
					</div>
				)}
			</div>
			{!disableError && <ErrorLabel error={errors} role="alert" />}
		</>
	);
});

export default AmountInput;
