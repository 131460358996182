import { FC } from 'react';

import Button from '@/components/Button';
import IButtonGroup from '@/components/ButtonGroup/index.d';

const getDirection = (direction: IButtonGroup.Direction, gridSize = 5) => {
	switch (direction) {
		case 'row':
			return `grid grid-cols-${
				gridSize < 5 ? gridSize.toString() : '5'
			} md:flex md:flex-row gap-0.5`;
		case 'col':
			return '';
		case 'row-mobile-col':
			return 'flex flex-col md:flex-row gap-0.5';
		default:
			return 'flex flex-col gap-0.5';
	}
};

const ButtonGroup: FC<IButtonGroup.IProps> = ({
	itemList,
	active,
	variant = 'reverse',
	activeVariant = 'reverse-content',
	className = '',
	direction = 'row',
	onClick = () => {},
	size = 'medium',
}) => {
	const getButtonVariant = (item: any) => {
		if (item.disabled) {
			return 'tone-contrast-disabled';
		}
		if (active === item.id) {
			return activeVariant;
		}
		return variant;
	};

	const gridSize = itemList?.length || 5;

	return (
		<div className={`${getDirection(direction, gridSize)} ${className}`}>
			{itemList.map((item) => (
				<Button
					showTooltip={item.disabled && Boolean(item.tooltipText)}
					key={item.id}
					type="button"
					size={size}
					label={`${item.label}`}
					tooltipText={item.tooltipText}
					variant={getButtonVariant(item)}
					onClick={() => {
						if (!item.disabled) {
							onClick(item);
						}
					}}
					badge={item?.badge}
				/>
			))}
		</div>
	);
};
export default ButtonGroup;
