const phoneMask = (locale?: string) => {
	switch (locale) {
		case 'ie': {
			return [/[0-9]/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
		}
		case 'es': {
			return [/[0-9]/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
		}
		case 'nl': {
			// TODO: Check if this is correct for dutch.
			return [/[0-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
		}
		case 'de': {
			return [/[0-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
		}
		case 'gb':
		default: {
			return [/[0-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
		}
	}
};

const ssnMaskPattern = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const phoneUnMaskValue = (value: string) => {
	// If we gonna remove country code we should put before all other search values.
	//	const searchValues = ['+44 ', ' ', '-', '(', ')'];

	const searchValues = [' ', '-', '(', ')', '_'];
	let unmaskedValue = value;
	searchValues.map((item) => {
		unmaskedValue = unmaskedValue.split(item).join('');
		return searchValues;
	});

	return unmaskedValue;
};

const amountUnMaskValue = (
	value: string,
	prefix: string,
	suffix: string,
	decimalSymbol: string,
	thousandsSeparatorSymbol: string
) => {
	let unmaskedValue = value;
	if (suffix && suffix.length > 0) {
		unmaskedValue = unmaskedValue.split(suffix).join('');
	}
	if (prefix && prefix.length > 0) {
		unmaskedValue = unmaskedValue.split(prefix).join('');
	}

	if (thousandsSeparatorSymbol && thousandsSeparatorSymbol.length > 0) {
		unmaskedValue = unmaskedValue.split(thousandsSeparatorSymbol).join('');
	}
	if (decimalSymbol && decimalSymbol.length > 0) {
		unmaskedValue = unmaskedValue.split(decimalSymbol).join('.');
	}

	return unmaskedValue;
};

const amountMaskValue = (
	value: string,
	decimalSymbol: string,
	thousandsSeparatorSymbol: string
) => {
	let maskedValue = value;

	if (thousandsSeparatorSymbol && thousandsSeparatorSymbol.length > 0) {
		maskedValue = value.split(',').join(thousandsSeparatorSymbol);
	}
	if (decimalSymbol && decimalSymbol.length > 0) {
		maskedValue = value.split('.').join(decimalSymbol);
	}

	return maskedValue;
};

// const traditionalDateMaskFormatValue -> dd/mm/yyyy
const traditionalDateMaskFormatValue = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export {
	amountMaskValue,
	amountUnMaskValue,
	phoneMask,
	phoneUnMaskValue,
	ssnMaskPattern,
	traditionalDateMaskFormatValue,
};
