import { useEffect, useState } from 'react';

const useWindowWidth = () => {
	const [windowWidth, setWindowWidth] = useState(
		typeof window !== 'undefined' ? window?.innerWidth : ''
	);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const handleWindowResize = () => {
				setWindowWidth(window.innerWidth);
			};

			window.addEventListener('resize', handleWindowResize);
			return () => window.removeEventListener('resize', handleWindowResize);
		}
		return () => '';
	}, []);

	return windowWidth;
};

export default useWindowWidth;
