import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { GERMANY, IRELAND, UNITED_KINGDOM } from '@/constants/locales';
import { IStoreAndAPIShared } from '@/redux/shared/index.d';

const calculateRangeMinAmount = (locale: string = UNITED_KINGDOM) => {
	switch (locale) {
		case UNITED_KINGDOM || IRELAND:
			return [{ amount: 60, installment: 4 }];
		default:
			return undefined;
	}
};

// useCalculator hook is used for static installment tables
const useCalculator = () => {
	const { locale = UNITED_KINGDOM } = useRouter();
	const localizedMinPrice = calculateRangeMinAmount(locale);

	// selectedProduct is a static variable to use for static pages.
	const selectedProduct = {
		active: true,
		deposit: false,
		deposit_percentage: '',
		equal_instalment: false,
		id: 4,
		initial: false,
		instalments: 4,
		last_updated: '',
		max: '',
		min: '',
		name: '',
		sola_id: '',
	};

	const [selectedInstallment, setSelectedInstallment] =
		useState<IStoreAndAPIShared.IProduct>(selectedProduct);
	const [deposit, setDeposit] = useState<boolean>(false);

	const [minPrice, setMinPrice] = useState(60);

	useEffect(() => {
		if ([GERMANY].includes(locale)) {
			setDeposit(true);
		} else {
			setDeposit(false);
		}
	}, [locale]);

	useEffect(() => {
		const rangeMinPrice = localizedMinPrice?.find(
			(i) => i.installment === selectedInstallment?.id
		)?.amount;

		if (rangeMinPrice) {
			setMinPrice(rangeMinPrice);
		}
	}, [selectedInstallment]);

	const productsHandler = (list: number[]) => {
		let products;
		if (list && list.length > 0) {
			products = list.map((data) => ({
				active: true,
				deposit: false,
				deposit_percentage: '',
				equal_instalment: false,
				id: data,
				initial: false,
				instalments: data,
				last_updated: '',
				max: '',
				min: '',
				name: data.toString(),
				sola_id: '',
			}));
		}
		return products;
	};

	const getProductList = () => {
		switch (locale) {
			case 'de':
				return productsHandler([3, 4, 6, 9]);
			case 'nl':
				return productsHandler([2, 3, 4]);
			case 'es':
				return productsHandler([3, 4, 6]);
			case 'ie':
				return productsHandler([1, 2, 3, 4]);
			default:
				return productsHandler([1, 2, 3, 4, 5, 6]);
		}
	};

	return {
		deposit,
		getProductList,
		minPrice,
		selectedInstallment,
		setDeposit,
		setSelectedInstallment,
	};
};

export { useCalculator };
